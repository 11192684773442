html,
body {
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
  color: white;
  text-shadow: 0 0 2em rgba(37, 60, 64, 0.5);
}

#app {
  top: 0;
  left: 0;
  position: absolute;
}

.pixelated #three_canvas {
  image-rendering: pixelated;
}

#three_canvas {
  width: 100vw !important;
  height: 100vh !important;
  background: linear-gradient(to bottom, #4ca1af, #c4e0e5);
  /* background: linear-gradient(to bottom, #23074d, #cc5333); */
}

.actions {
  top: 10px;
  right: 10px;
  position: fixed;
}

.debug {
  width: -webkit-fill-available;
  bottom: 10px;
  left: 10px;
  position: fixed;
  font-size: 2em;
  justify-content: space-between;
  display: flex;
}

.glass {
  color: white;
  background: rgba(0, 0, 0, 0.24);
  border-image: linear-gradient(60deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6)) 10%;
  border-width: 1px;
  border-style: solid;
  backdrop-filter: blur(6px);
  transition: border-image, backdrop-filter 200ms;
}

.glass:hover {
  border-image: linear-gradient(60deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 1)) 10%;
  backdrop-filter: blur(20px);
}

.glass:disabled {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 300;
  background-color: rgba(0, 0, 0, 0.4);
  border-image: linear-gradient(60deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)) 10%;
}

.glass:disabled:hover {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 300;
  background-color: rgba(0, 0, 0, 0.4);
  border-image: linear-gradient(60deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 1)) 10%;
}

.glass-slide,
.glass-checkbox {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  margin: 20px auto;
  padding: 12px;
  font-size: 26px;
  font-weight: 200;
  box-sizing: border-box;
  position: relative;
  display: block;
  transition: color 200ms;
}

.glass-slide:hover {
  color: rgba(0, 0, 0, 0.3);
}

.glass-slide input[type="range"] {
  top: 0;
  left: 0;
  width: calc(100% - 4px);
  height: calc(100% - 6px);
  position: absolute;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
}

.glass-slide input[type="range"]::-webkit-slider-runnable-track {
  background: transparent;
  border: transparent;
}

.glass-slide input[type="range"]::-webkit-slider-thumb {
  width: 10px;
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  margin-top: 2px;
  height: 51px;
}

.glass-slide:hover input[type="range"]::-webkit-slider-thumb {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 12px rgba(255, 255, 255, 0.6);
}

.glass-checkbox {
  padding: 0;
}

.glass-checkbox input[type="checkbox"] {
  display: none;
}

.glass-checkbox input[type="checkbox"]+label {
  width: 100%;
  height: 100%;
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.3);
  display: block;
  box-sizing: border-box;
  box-shadow: 0 0 0 rgba(255, 255, 255, 0.0), inset 0 0 0 rgba(255, 255, 255, 0);
  transition: box-shadow 200ms, background-color 200ms;
}


.glass-checkbox input[type="checkbox"]:checked+label {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.5), inset 0 0 15px rgba(255, 255, 255, 0.5);
}


button {
  font-family: "Raleway", sans-serif;
  font-weight: 200;
  text-shadow: 0 0 1em rgba(37, 60, 64, 0.7);
}

.screen {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.hidden {
  display: none !important;
}

.menu-button {
  width: 100%;
  max-width: 400px;
  font-size: 26px;
  margin: 20px auto;
  padding: 12px;
  display: block;
}

.menu-title {
  font-size: 3em;
  font-weight: 800;
  margin-bottom: 0.6em;
}

.menu-hint {
  max-width: 600px;
  margin: 0 auto;
  font-weight: 300;
  line-height: 1.5em;
  text-align: left;
  padding: 0.5em 1em;
}

.menu-hint p {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

/** Joycon Screen **/

.connect_section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.connect_bt {
  padding: 0.5em 2em;
  font-size: 1.6em;
  display: flex;
  min-width: 280px;
  justify-content: center;
  align-items: center;
}

.connect_bt .joycon {
  height: 5.5em;
  margin-right: 0.3em;
}

.connect_bt .hint {
  font-weight: 400;
  padding-right: 0.5em;
  text-align: start;
}

.connect_bt .large {
  font-size: 1.6em;
  font-weight: 800;
}

/** Title Screen **/

#title_screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.centered_section {
  top: 50%;
  width: 100%;
  height: min-content;
  text-align: center;
  position: absolute;
  transform: translateY(-50%);
}

.title_section>div {
  text-align: center;
}

.title_section .logo {
  width: 100%;
  max-width: 600px;
  margin: 40px 0;
}

.connections {
  bottom: 28px;
  right: 18px;
  position: fixed;
}

.connections .glass {
  padding: 8px;
  margin: 0 10px;
}

.connections img {
  width: 20px;
  display: block;
}

.player-tag {
  margin-top: 6px;
  margin-bottom: -2px;
  font-size: 12px;
  font-weight: 500;
  display: block;
}

/** Diagnosis screen **/

.diagnosis-charts {
  margin: 0 auto;
  max-width: 800px;
}

.diagnosis-sparkline-container {
  margin: 7px;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
}

.diagnosis-sparkline-container .label {
  top: 0;
  left: 0;
  height: calc(100% - 2px);
  width: 35%;
  padding: 0 8px;
  margin: 1px;
  font-size: 0.6em;
  text-align: left;
  margin-right: 8px;
  overflow: hidden;
  line-height: 50px;
  display: inline-block;
  position: absolute;
  font-weight: 600;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
}

.p2-connected .connect-p2-button {
  display: none;
}

.settings-p2-button {
  display: none;
}

.p2-connected .settings-p2-button {
  display: inline;
}

.p1 .diagnosis-p2 {
  display: none;
}

.p2 .diagnosis-p1 {
  display: none;
}

.settings-bot-p1 {
  display: none !important;
}

.settings-bot-p2 {
  display: none !important;
}

.p1-bot .settings-p1 {
  display: none;
}

.p1-bot .settings-bot-p1 {
  display: block !important;
}

.p2-bot .connect-p2 {
  display: none;
}

.p2-bot .settings-p2 {
  display: none;
}

.p2-bot .settings-bot-p2 {
  display: block !important;
}

/** Single Player **/
.stat {
  top: 0;
  right: 0;
  position: fixed;
  display: flex;
}

.stat-item {
  margin: 20px;
  text-align: center;
}

.stat-item .value {
  font-size: 3.8em;
  font-family: 'Inconsolata';
  font-weight: 800;
}

.stat-item .p2 {
  color: rgb(244, 67, 54);
}

.stat-item .title.p2 {
  font-weight: 400;
}

.finish-training {
  bottom: 20px;
  left: 20px;
  padding: 0.25em 1em;
  font-size: 1.5em;
  font-weight: 300;
  position: fixed;
}

.right-panel {
  --center-alpha: 0.75;
  --edge-size: 15%;
  --high-limit: 30%;

  position: fixed;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  height: 400px;
}

.right-panel .bar {
  width: 10px;
  height: 100%;
  background:
    linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, var(--center-alpha)) var(--edge-size), rgba(255, 255, 255, var(--center-alpha)) calc(100% - var(--edge-size)), rgba(255, 255, 255, 0) 100%);
}

.right-panel .current-value {
  top: 0;
  width: 16px;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid white;
  position: absolute;
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.6);
  transform: translateX(-3px);
  box-sizing: border-box;
  display: block;
}

.hp-bar {
  top: 50px;
  left: 30px;
  width: 660px;
  height: 20px;
  position: fixed;
  background: rgba(0, 0, 0, 0.24);
  border: rgba(255, 255, 255, 0.6) 1px solid;
  backdrop-filter: blur(6px);
}

.hp-bar-val {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.0) 50%, rgba(255, 255, 255, 0.5));
}

.spm-stat {
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -55%);
  visibility: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  border-image: linear-gradient(60deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6)) 10%;
  border-width: 1px;
  border-style: solid;
  backdrop-filter: blur(24px);
  transition: translate 300ms, opacity 300ms, visibility 300ms;
}

.spm-stat.shown {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, -50%);
}

.spm-chart-container {
  width: 600px;
  height: 280px;
  background: rgba(0, 0, 0, 0.5);
  position: relative;
}

.spm-chart-container canvas {
  top: 0;
  left: 0;
  position: absolute;
}

.spm-stat .modal-title {
  margin-top: 0.9em;
  margin-bottom: 0.2em;
  text-align: center;
  font-size: 2em;
  font-weight: 800;
}

.spm-stat .final-stat {
  font-size: 0.8em;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.final-stat .value {
  font-weight: 700;
}

[data-\:\:mode="multiple"] .time-title {
  display: none;
}

[data-\:\:mode="single"] .p2 {
  display: none;
}

[data-\:\:mode="single"] #spm-chart-p2 {
  display: none;
}

/** Story List **/

.stories {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stories .empty-content {
  height: 400px;
  margin-bottom: 120px;
}

.story-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
}

.story-list li {
  transition: transform 150ms;
}

.story-list li:hover {
  transform: scale(1.2);
}

.story-list li:hover > div {
  border: 1px solid rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.5), inset 0 0 15px rgba(255, 255, 255, 0.4);
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
}

.story-list li > div {
  --size: 140px;
  display: block;
  width: var(--size);
  min-width: var(--size);
  height: var(--size);
  border: 1px solid white;
  margin: 0 32px;
  text-align: center;
  line-height: var(--size);
  font-size: 4.8em;
  transform: rotate(45deg);
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0), inset 0 0 15px rgba(255, 255, 255, 0);
  text-shadow: 0 0 15px rgba(255, 255, 255, 0);
  border: 1px solid rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(6px);
  transition: box-shadow 150ms, border 150ms, text-shadow 150ms;
}

.story-list li > div > span {
  display: block;
  transform: rotate(-45deg);
}

/** Device disconnected **/

.reconnect {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  backdrop-filter: blur(20px);
  padding: 0.5em 2em;
  font-size: 1.6em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reconnect .joycon {
  height: 5.5em;
  margin-right: 0.6em;
}

.reconnect .hint {
  font-size: 1.2em;
  line-height: 1.4em;
  font-weight: 300;
}

.disconnected {
  font-size: 1.3em;
  font-weight: 800;
}

/** Other debugging tools **/
.story-visualize-container {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 30vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(6px);
}

.story-visualize-container canvas {
  height: 100%;
}

.vis-time-axis .vis-text {
  color: white;
  font-weight: 400;
}

.vis-item-content {
  font-size: 14px;
  font-weight: 400;
}

.vis-timeline {
  border-color: rgba(255, 255, 255, 0.3);
}

.vis-time-axis .vis-grid.vis-minor {
  border-color: rgba(255, 255, 255, 0.3);
}

.vis-panel.vis-center, .vis-panel.vis-left, .vis-panel.vis-right, .vis-panel.vis-top, .vis-panel.vis-bottom {
  border-color: rgba(255, 255, 255, 0.3);
}

.vis-item {
  border-radius: 0;
  background: rgba(0, 0, 0, 0.24);
  border-image: linear-gradient(60deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6)) 10%;
  box-shadow: 0 0 0 rgba(255, 255, 255, 0.0), inset 0 0 0 rgba(255, 255, 255, 0);
  color: white;
}

.vis-item.vis-selected {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.5), inset 0 0 4px rgba(255, 255, 255, 0.5);
  border-image: linear-gradient(60deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 1)) 10%;
}