html, body {
  font-optical-sizing: auto;
  color: #fff;
  text-shadow: 0 0 2em #253c4080;
  margin: 0;
  padding: 0;
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 100;
}

#app {
  position: absolute;
  top: 0;
  left: 0;
}

.pixelated #three_canvas {
  image-rendering: pixelated;
}

#three_canvas {
  background: linear-gradient(#4ca1af, #c4e0e5);
  width: 100vw !important;
  height: 100vh !important;
}

.actions {
  position: fixed;
  top: 10px;
  right: 10px;
}

.debug {
  justify-content: space-between;
  width: -webkit-fill-available;
  font-size: 2em;
  display: flex;
  position: fixed;
  bottom: 10px;
  left: 10px;
}

.glass {
  color: #fff;
  backdrop-filter: blur(6px);
  background: #0000003d;
  border-style: solid;
  border-width: 1px;
  border-image: linear-gradient(60deg, #fff9, #fff0, #fff9) 10%;
  transition: border-image, -webkit-backdrop-filter .2s, backdrop-filter .2s;
}

.glass:hover {
  backdrop-filter: blur(20px);
  border-image: linear-gradient(60deg, #fff, #fff6, #fff) 10%;
}

.glass:disabled {
  color: #0009;
  background-color: #0006;
  border-image: linear-gradient(60deg, #0009, #0000, #0009) 10%;
  font-weight: 300;
}

.glass:disabled:hover {
  color: #0009;
  background-color: #0006;
  border-image: linear-gradient(60deg, #000, #0006, #000) 10%;
  font-weight: 300;
}

.glass-slide, .glass-checkbox {
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
  padding: 12px;
  font-size: 26px;
  font-weight: 200;
  transition: color .2s;
  display: block;
  position: relative;
}

.glass-slide:hover {
  color: #0000004d;
}

.glass-slide input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background: none;
  width: calc(100% - 4px);
  height: calc(100% - 6px);
  position: absolute;
  top: 0;
  left: 0;
}

.glass-slide input[type="range"]::-webkit-slider-runnable-track {
  background: none;
  border: #0000;
}

.glass-slide input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff3;
  border-radius: 5px;
  width: 10px;
  height: 51px;
  margin-top: 2px;
}

.glass-slide:hover input[type="range"]::-webkit-slider-thumb {
  background-color: #fffc;
  box-shadow: 0 0 12px #fff9;
}

.glass-checkbox {
  padding: 0;
}

.glass-checkbox input[type="checkbox"] {
  display: none;
}

.glass-checkbox input[type="checkbox"] + label {
  box-sizing: border-box;
  background-color: #0000004d;
  width: 100%;
  height: 100%;
  padding: 12px;
  transition: box-shadow .2s, background-color .2s;
  display: block;
  box-shadow: 0 0 #fff0, inset 0 0 #fff0;
}

.glass-checkbox input[type="checkbox"]:checked + label {
  background-color: #ffffff1a;
  box-shadow: 0 0 15px #ffffff80, inset 0 0 15px #ffffff80;
}

button {
  text-shadow: 0 0 1em #253c40b3;
  font-family: Raleway, sans-serif;
  font-weight: 200;
}

.screen {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hidden {
  display: none !important;
}

.menu-button {
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
  padding: 12px;
  font-size: 26px;
  display: block;
}

.menu-title {
  margin-bottom: .6em;
  font-size: 3em;
  font-weight: 800;
}

.menu-hint {
  text-align: left;
  max-width: 600px;
  margin: 0 auto;
  padding: .5em 1em;
  font-weight: 300;
  line-height: 1.5em;
}

.menu-hint p {
  margin-block: .5em;
}

.connect_section {
  justify-content: center;
  align-items: center;
  display: flex;
}

.connect_bt {
  justify-content: center;
  align-items: center;
  min-width: 280px;
  padding: .5em 2em;
  font-size: 1.6em;
  display: flex;
}

.connect_bt .joycon {
  height: 5.5em;
  margin-right: .3em;
}

.connect_bt .hint {
  text-align: start;
  padding-right: .5em;
  font-weight: 400;
}

.connect_bt .large {
  font-size: 1.6em;
  font-weight: 800;
}

#title_screen {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.centered_section {
  text-align: center;
  width: 100%;
  height: min-content;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.title_section > div {
  text-align: center;
}

.title_section .logo {
  width: 100%;
  max-width: 600px;
  margin: 40px 0;
}

.connections {
  position: fixed;
  bottom: 28px;
  right: 18px;
}

.connections .glass {
  margin: 0 10px;
  padding: 8px;
}

.connections img {
  width: 20px;
  display: block;
}

.player-tag {
  margin-top: 6px;
  margin-bottom: -2px;
  font-size: 12px;
  font-weight: 500;
  display: block;
}

.diagnosis-charts {
  max-width: 800px;
  margin: 0 auto;
}

.diagnosis-sparkline-container {
  box-sizing: border-box;
  margin: 7px;
  display: inline-block;
  position: relative;
}

.diagnosis-sparkline-container .label {
  text-align: left;
  background: linear-gradient(90deg, #0009 0%, #0000 100%);
  width: 35%;
  height: calc(100% - 2px);
  margin: 1px 8px 1px 1px;
  padding: 0 8px;
  font-size: .6em;
  font-weight: 600;
  line-height: 50px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.p2-connected .connect-p2-button, .settings-p2-button {
  display: none;
}

.p2-connected .settings-p2-button {
  display: inline;
}

.p1 .diagnosis-p2, .p2 .diagnosis-p1 {
  display: none;
}

.settings-bot-p1, .settings-bot-p2 {
  display: none !important;
}

.p1-bot .settings-p1 {
  display: none;
}

.p1-bot .settings-bot-p1 {
  display: block !important;
}

.p2-bot .connect-p2, .p2-bot .settings-p2 {
  display: none;
}

.p2-bot .settings-bot-p2 {
  display: block !important;
}

.stat {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
}

.stat-item {
  text-align: center;
  margin: 20px;
}

.stat-item .value {
  font-family: Inconsolata;
  font-size: 3.8em;
  font-weight: 800;
}

.stat-item .p2 {
  color: #f44336;
}

.stat-item .title.p2 {
  font-weight: 400;
}

.finish-training {
  padding: .25em 1em;
  font-size: 1.5em;
  font-weight: 300;
  position: fixed;
  bottom: 20px;
  left: 20px;
}

.right-panel {
  --center-alpha: .75;
  --edge-size: 15%;
  --high-limit: 30%;
  height: 400px;
  position: fixed;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.right-panel .bar {
  background: linear-gradient(0deg, #fff0 0%, rgba(255, 255, 255, var(--center-alpha)) var(--edge-size), rgba(255, 255, 255, var(--center-alpha)) calc(100% - var(--edge-size)), #fff0 100%);
  width: 10px;
  height: 100%;
}

.right-panel .current-value {
  box-sizing: border-box;
  background-color: #fffc;
  border: 1px solid #fff;
  width: 16px;
  height: 6px;
  display: block;
  position: absolute;
  top: 0;
  transform: translateX(-3px);
  box-shadow: 0 0 8px #fff9;
}

.hp-bar {
  backdrop-filter: blur(6px);
  background: #0000003d;
  border: 1px solid #fff9;
  width: 660px;
  height: 20px;
  position: fixed;
  top: 50px;
  left: 30px;
}

.hp-bar-val {
  background: linear-gradient(90deg, #fff0 50%, #ffffff80);
  width: 100%;
  height: 100%;
}

.spm-stat {
  visibility: hidden;
  opacity: 0;
  backdrop-filter: blur(24px);
  background: #00000080;
  border-style: solid;
  border-width: 1px;
  border-image: linear-gradient(60deg, #fff9, #fff0, #fff9) 10%;
  transition: translate .3s, opacity .3s, visibility .3s;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
}

.spm-stat.shown {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, -50%);
}

.spm-chart-container {
  background: #00000080;
  width: 600px;
  height: 280px;
  position: relative;
}

.spm-chart-container canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.spm-stat .modal-title {
  text-align: center;
  margin-top: .9em;
  margin-bottom: .2em;
  font-size: 2em;
  font-weight: 800;
}

.spm-stat .final-stat {
  justify-content: space-around;
  align-items: center;
  font-size: .8em;
  display: flex;
}

.final-stat .value {
  font-weight: 700;
}

[data-\:\:mode="multiple"] .time-title, [data-\:\:mode="single"] .p2, [data-\:\:mode="single"] #spm-chart-p2 {
  display: none;
}

.stories {
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
}

.stories .empty-content {
  height: 400px;
  margin-bottom: 120px;
}

.story-list {
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.story-list li {
  transition: transform .15s;
}

.story-list li:hover {
  transform: scale(1.2);
}

.story-list li:hover > div {
  text-shadow: 0 0 15px #ffffff80;
  border: 1px solid #fffc;
  box-shadow: 0 0 15px #ffffff80, inset 0 0 15px #fff6;
}

.story-list li > div {
  --size: 140px;
  width: var(--size);
  min-width: var(--size);
  height: var(--size);
  text-align: center;
  line-height: var(--size);
  box-sizing: border-box;
  text-shadow: 0 0 15px #fff0;
  backdrop-filter: blur(6px);
  background: #0000004d;
  border: 1px solid #0000004d;
  margin: 0 32px;
  font-size: 4.8em;
  transition: box-shadow .15s, border .15s, text-shadow .15s;
  display: block;
  transform: rotate(45deg);
  box-shadow: 0 0 15px #fff0, inset 0 0 15px #fff0;
}

.story-list li > div > span {
  display: block;
  transform: rotate(-45deg);
}

.reconnect {
  backdrop-filter: blur(20px);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: .5em 2em;
  font-size: 1.6em;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.reconnect .joycon {
  height: 5.5em;
  margin-right: .6em;
}

.reconnect .hint {
  font-size: 1.2em;
  font-weight: 300;
  line-height: 1.4em;
}

.disconnected {
  font-size: 1.3em;
  font-weight: 800;
}

.story-visualize-container {
  backdrop-filter: blur(6px);
  background: #0000003d;
  width: 100%;
  height: 30vh;
  position: fixed;
  bottom: 0;
  left: 0;
}

.story-visualize-container canvas {
  height: 100%;
}

.vis-time-axis .vis-text {
  color: #fff;
  font-weight: 400;
}

.vis-item-content {
  font-size: 14px;
  font-weight: 400;
}

.vis-timeline, .vis-time-axis .vis-grid.vis-minor, .vis-panel.vis-center, .vis-panel.vis-left, .vis-panel.vis-right, .vis-panel.vis-top, .vis-panel.vis-bottom {
  border-color: #ffffff4d;
}

.vis-item {
  color: #fff;
  background: #0000003d;
  border-image: linear-gradient(60deg, #fff9, #fff0, #fff9) 10%;
  border-radius: 0;
  box-shadow: 0 0 #fff0, inset 0 0 #fff0;
}

.vis-item.vis-selected {
  background-color: #ffffff1a;
  border-color: #ffffff4d;
  border-image: linear-gradient(60deg, #fff, #fff9, #fff) 10%;
  box-shadow: 0 0 4px #ffffff80, inset 0 0 4px #ffffff80;
}
/*# sourceMappingURL=index.d9bd1d2a.css.map */
